type WidgetName = 'header' | 'footer' | 'notification';
export const addComponentTrackingCode = (component: string, trackingCodeValue: WidgetName) => {
    document.querySelector(component)?.setAttribute('data-cbtrack-corewidget', trackingCodeValue);
};

export const fireComponentLoaded = (component: WidgetName) => {
    document.dispatchEvent(
        new CustomEvent('cbTrack-widgetsCoreEvent', {
            bubbles: true,
            detail: {
                widgetKey: component, //option: header, footer, notification
                actionType: 'dom-ready',
            },
        }),
    );
    document.dispatchEvent(
        new CustomEvent('cbw-mounted', {
            bubbles: true,
            detail: {
                widget: component, //option: header, footer, notification
            },
        }),
    );
};
