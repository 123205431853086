export const anonymousTemplate = `
<a href="#widget-login-control" aria-label="Sign in" class="cbw-header-login">
  <span class="cbw-header-login-text">
    <strong>Sign In</strong>
  </span>
  <span
    class="cbw-icon cbw-icon-circular cbw-user cbw-black cbw-hidden-xs"
    aria-hidden="true"
  ></span>
</a>
`;
export const authenticatedTemplate = `
<a href="#widget-login-control" aria-expanded="false" aria-label="Hi, {{firstName}} (account and login settings)" class="cbw-header-login"
  data-cbtrack-linktype="toggle"
  data-cbtrack-label="Toggle Account Panel">
  <span class="cbw-header-login-text">
    Hi, <strong>{{firstName}}</strong>
  </span>
  <span
    class="cbw-icon cbw-icon-circular cbw-user cbw-black cbw-hidden-xs"
    aria-hidden="true"
  ></span>
</a>
`;
