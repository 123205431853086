// Declare constant with type array of string keys and string value
export const logoClasses: { [key: string]: string } = {
    sat: 'cbw-sat',
    pn: 'cbw-psat-nmsqt',
    pn10: 'cbw-psat-10',
    p89: 'cbw-psat-8-9',
    sb: 'cbw-springboard',
    ap: 'cbw-ap',
    accs: 'cbw-big-future',
    accu: 'cbw-accuplacer',
    clep: 'cbw-clep',
    css: 'cbw-css-profile',
    cbsch: 'cbw-search',
    pfaid: 'cbw-powerfaids',
};
