export const shadowLinkCloner = (component: HTMLElement) => {
    const cloneAndClick = (event: Event) => {
        let target = event.target as HTMLElement;
        // GH Links have a span inside the anchors
        if (target && target.tagName === 'SPAN' && target.parentNode) {
            target = target.parentNode as HTMLElement;
        }
        // we only want to track nav items
        if (target.tagName === 'A' && target.getAttribute('data-cbtrack-linktype') === 'nav') {
            event.preventDefault();
            component.querySelector('.cb-cloned-shadow-link')?.remove();
            const link: HTMLAnchorElement = target.cloneNode(true) as HTMLAnchorElement;
            link.setAttribute('aria-hidden', 'true');
            link.classList.add('cb-hidden', 'cb-cloned-shadow-link');
            component.appendChild(link);

            link.click();
        }
    };
    component.shadowRoot && component.shadowRoot.addEventListener('click', (event: Event) => cloneAndClick(event));
};
