export const template = `
<style>{{styles}}</style>
<div class="cbw-header-search">
  <div class="">
    <form action="{{searchURL}}" method="get">
      <input type="hidden" name="searchType" value="{{searchType}}"/>
      <input
          name="query"
          type="text"
          class="cbw-input"
          aria-label="College Board Search"
          aria-activedescendant=""
          aria-describedby=""
          aria-autocomplete="list"
          aria-controls="{{searchListId}}"
          aria-haspopup="listbox"
          autocomplete="off"
          data-search="search"
          placeholder="Search"
          title="Search"
      />
      <button
        class="cbw-header-search-btn" 
        type="submit"
        aria-label="Submit Search"
        disabled="true"
        >
        <span class="cbw-icon cbw-search"></span>
      </button>
    </form>
    <span role="status" class="cbw-search-suggestions-status sr-only"></span>
    <ul 
      id="{{searchListId}}"
      class="cbw-search-suggestions cbw-hidden"
      role="listbox" 
      aria-label="suggested search results for your search term"
      aria-hidden="true"
    ></ul>
  </div>
</div>
`;

export const suggestionItem = `
    <li id='suggestion-{{id}}'
          aria-selected="false"
          role="option"
          tabIndex="-1"
          data-cbtrack-linktype="nav">{{label}}</li>`;
