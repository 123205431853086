export default `
<style>{{notificationsStyle}}</style>

<div class="cbw-widget cbw-notification-widget" part="cbw-widget">
  <div class="cbw-container">
    <div class="cbw-row">
      <div class="cbw-offset-md-1 cbw-col-md-10 cbw-offset-lg-2 cbw-col-lg-8 cbw-offset-xl-2 cbw-col-xl-8 cbw-offset-2xl-3 cbw-col-2xl-6 cbw-offset-3xl-3 cbw-col-3xl-6">
        <div class="cbw-notification-container">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</div>`;
