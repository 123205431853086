/* eslint-disable max-lines-per-function */
import { camelCase } from 'lodash-es';

import template from './footer.template';
import { scrollToGlobalHeader } from './backToTop';
import { formatLinks, getLinksMarkup } from './links';
import { FooterConfig, FooterLink } from './types';
import { defaultFooterLinks } from './defaultFooterLinks';

import fontsStyle from '../../../../core/src/scss/fonts.scss?inline';
import footerStyle from './footer.scss?inline';
import stringReplacer from '../../../../core/src/ts/stringReplacer';
import { shadowLinkCloner } from '../../../../core/src/ts/analytics/shadowLinkCloner';
import { addComponentTrackingCode, fireComponentLoaded } from '../../../../core/src/ts/analytics/analytics';
import { apricotVersion } from '../../../../core/src/ts/variables/variables';

class Footer extends HTMLElement {
    constructor() {
        super();

        // Add a shadow DOM
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const shadowDOM = this.attachShadow({ mode: 'open' });
    }

    connectedCallback() {
        const shadowDOM = this.shadowRoot;
        if (!shadowDOM) {
            return;
        }

        const defaultData: FooterConfig = {
            linksUrl: 'https://athena.collegeboard.org/api/footer-links.json',
            topTargetId: 'cbw-header',
            showLinks: true,
            showBackToTopLink: true,
            color: 'light',
            backgroundColor: '#1e1e1e',
            backToTopColor: 'dark',
            backToTopBackgroundColor: '#fedb00',
            removeTopMargin: false,
        };

        const stringParams = [
            'linksUrl',
            'topTargetId',
            'color',
            'backgroundColor',
            'backToTopColor',
            'backToTopBackgroundColor',
        ];
        const boolParams = ['showLinks', 'showBackToTopLink', 'removeTopMargin'];
        const attributes = this.getAttributeNames();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let data: any = {};
        attributes.forEach(attribute => {
            const param = camelCase(attribute);
            if (stringParams.includes(param)) {
                data[param] = this.getAttribute(attribute);
            } else if (boolParams.includes(param)) {
                data[param] = this.getAttribute(attribute) === 'true';
            }
        });

        data = {
            ...defaultData,
            ...data,
        };

        const renderLinks = (links: FooterLink[]) => {
            const linkDiv: HTMLElement | null = shadowDOM.querySelector('.cbw-footer-links-container .cbw-row');
            if (linkDiv && data.showLinks === false) {
                linkDiv.innerHTML = '';
            } else {
                if (linkDiv) {
                    // Render the links.
                    const items: FooterLink[] = formatLinks(links, 'gf');
                    linkDiv.innerHTML = getLinksMarkup(items, data.color).innerHTML;
                }
            }
        };

        const thisYear: number = new Date().getFullYear();

        const replacements: { [key: string]: string } = {
            footerStyle: footerStyle.toString(),
            thisYear: thisYear.toString(),
            backToTop: 'Back to top of page',
            top: 'Top',
            removeTopMarginClass: data.removeTopMargin ? 'cbw-remove-top-margin' : '',
        };

        shadowDOM.innerHTML = stringReplacer(template, replacements);
        // Add analytics tracking code to the light Dom web component element.
        addComponentTrackingCode('cbw-footer', 'footer');
        fireComponentLoaded('footer');

        try {
            const linksUrl = data.linksUrl || 'https://athena.collegeboard.org/api/footer-links.json';
            fetch(linksUrl)
                .then(res => {
                    res.json()
                        .then(data => {
                            renderLinks(data);
                        })
                        .catch(error => {
                            console.error(error);
                            renderLinks(defaultFooterLinks);
                        });
                })
                .catch(error => {
                    console.error(`Notification retrieval error: ${error}`);
                    renderLinks(defaultFooterLinks);
                });
        } catch (errors) {
            console.error(errors);
            renderLinks(defaultFooterLinks);
        }

        const navigation = shadowDOM.querySelector('.cbw-footer-navigation');
        const style = 'background: ' + data.backgroundColor + '; border-color: ' + data.backToTopBackgroundColor + ';';
        navigation && navigation.setAttribute('style', style);

        const backToTop = shadowDOM.querySelector('a.cbw-footer-top');
        if (!data.showBackToTopLink || !backToTop) {
            shadowDOM.querySelector('.cbw-footer-controls')?.remove();
        } else {
            const attributes = {
                href: '#' + data.topTargetId,
                style:
                    'background: ' +
                    data.backToTopBackgroundColor +
                    ';' +
                    'border-color: ' +
                    data.backToTopBackgroundColor +
                    ';',
            };
            for (const [key, value] of Object.entries(attributes)) {
                backToTop.setAttribute(key, value);
            }
            data.backToTopColor === 'light' && backToTop.classList.add('cbw-white-color');
            (backToTop as HTMLElement).onclick = (event: MouseEvent) => {
                scrollToGlobalHeader(event, data.topTargetId);
            };
        }

        const stylesheet = document.createElement('style');
        stylesheet.textContent = fontsStyle.toString();
        this.appendChild(stylesheet);

        const linkElem = document.createElement('link');
        linkElem.setAttribute('rel', 'stylesheet');
        // NOTE: This has to be updated to latest prod version for each release
        // https://uihub.collegeboard.org/4.5.0
        linkElem.setAttribute('href', `//atlas.collegeboard.org/apricot/prod/${apricotVersion}/widgets.css`);
        shadowDOM.appendChild(linkElem);

        shadowLinkCloner(this);
    }
}

export default Footer;
