import { remove, cloneDeep } from 'lodash-es';
import { HeaderLink } from './types';
import { logoClasses } from './logoClasses';

export const addQueryParam = (url: string, name: string, value: string) => {
    const pageUrl: URL = new URL(url);
    pageUrl.searchParams.append(name, value);
    return pageUrl.href;
};

export const formatLinks = (rawLinks: HeaderLink[], siteCode: string) => {
    const links = cloneDeep(rawLinks);
    links.forEach(link => {
        link.url = addQueryParam(link.url, 'navId', `${siteCode}-${link.linkCode}`);
    });
    for (const link of links) {
        (link as HeaderLink).children = remove(links, { group: link.name });
    }
    return links;
};

// Header Link Component
const getLink = ({ url, logo, name, linkCode, children }: HeaderLink) => {
    let topLink = '';
    if (linkCode !== 'morecb') {
        const logoClass: string = logo ? 'cbw-logo ' + logoClasses[linkCode] : '';
        topLink = `<a href="${url}" 
            class="${logoClass}"
            tabindex="-1"
            data-cbtrack-linktype="nav"
            data-cbtrack-label="${name}"
            ><span ${logo ? 'class=sr-only' : ''}>${name === 'Access' ? 'BigFuture' : name}</span></a>`;
    }
    let childrenMarkup = '';
    if (children && children.length > 0) {
        const subClassName = children[0].type == 'social' ? 'social-media' : 'sub-links';
        const childrenLinks = children.map(child => {
            if (child.glyph) {
                return `<li><a href=${child.url}
                class="cbw-btn cbw-btn-circle cbw-btn-light"
                tabindex="-1">
                <span class="cbw-icon cbw-${child.glyph}" aria-hidden="true"></span>
                <span class="sr-only">${child.glyph}</span>
              </a></li>`;
            } else {
                return `<li><a href=${child.url} aria-label="${child.name}" data-cbtrack-linktype="nav" data-cbtrack-label="${child.name}" tabindex="-1">
            <span>${child.name}</span></a></li>`;
            }
        });
        childrenMarkup = `<ul class="${subClassName}">${childrenLinks.join('')}</ul>`;
    }
    return `
  <li>
    ${topLink}
    ${childrenMarkup}
  </li>`;
};

export const getCategory = (links: HeaderLink[], offset = false, className: string, linkType: string): HTMLElement => {
    const wrapper: HTMLElement = document.createElement('DIV');
    wrapper.setAttribute('role', 'navigation');
    wrapper.setAttribute('aria-label', linkType);
    wrapper.classList.add('cbw-col-md-3', 'cbw-col-sm-5', 'cbw-col-md-offset-0', 'cbw-offset-md-0', 'cbw-category');
    if (offset) {
        wrapper.classList.add('cbw-col-sm-offset-1', 'cbw-offset-sm-1');
    }
    const innerDiv = document.createElement('DIV');
    innerDiv.classList.add(className, 'cbw-category');
    innerDiv.innerHTML = `<ul class="outerList">${links.map(getLink).join('')}</ul>`;
    wrapper.appendChild(innerDiv);
    return wrapper;
};
