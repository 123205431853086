/**
 * CBW String Replacer
 */

const stringReplacer = (template: string, variables: { [key: string]: string }): string => {
    // Token pattern
    const tokenPattern = /{{([a-zA-Z-_\d]+)}}/gim;
    // Search for strings that are wrapped with {{..}}
    const variableNames: IterableIterator<RegExpMatchArray> = template.matchAll(tokenPattern);
    const keys: string[] = [];
    for (const variable of variableNames) {
        keys.push(variable[1]);
    }

    for (const key in variables) {
        if (keys.indexOf(key) != -1) {
            template = template.replaceAll(`{{${key}}}`, variables[key]);
        }
    }

    template = template.replace(tokenPattern, '');

    return template;
};

export default stringReplacer;
