export default `
<div class="cbw-slidedown-wrapper">
  <div class="cbw-slidedown-nav cbw-nav-links cbw-hidden-xs-up" data-cbtrack-linkgroup="global-nav">
    <div class="cbw-slidedown-nav-panel cbw-header-navigation-panel cbw-invisible" data-testid="navigation-panel" role="navigation" aria-label="College Board Global Navigation" aria-hidden="true" style="overflow:auto;">
      <div class="cbw-slidedown-nav-wrapper cbw-header-navigation-wrapper cbw-transition transition-height" style="height:0px;">
        <div class="cbw-slidedown-nav-shade cbw-header-navigation-shade">
          <div class="cbw-container" style="height:100%">
            <div class="cbw-row">
              <div class="cbw-slidedown-nav-body cbw-nav-links-body"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cbw-slidedown-login cbw-hidden-xs-up">
    <div class="cbw-slidedown-login-panel cbw-invisible" data-cbtrack-linkgroup="account-panel" role="navigation" aria-label="Login and Account Panel" aria-hidden="true" style="overflow:auto;">
      <div class="cbw-slidedown-login-wrapper cbw-transition transition-height" style="height:0px;">
        <div class="cbw-slidedown-login-shade">
          <div class="cbw-container" style="height:100%">
            <div class="cbw-row">
              <div class="cbw-slidedown-login-body">
                <div class="cbw-personalized-identity">
                  <div class="cbw-loggedin">
                    <div class="cbw-loggedin-links">
                      <div class="cbw-greetings-wrapper"></div>
                      <div class="cbw-nav-wrapper">
                        <nav class="cbw-loggedin-links-nav" aria-label="Vertical Menu List Navigation"></nav>
                      </div>
                      <div class="cbw-signout-wrapper">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cbw-slidedown-search cbw-mobile-search cbw-hidden-xs-up">
    <div class="cbw-slidedown-search-panel cbw-header-search-panel" role="dialog" aria-label="Search Panel" 
    aria-hidden="true" data-cbtrack-linkgroup="search-panel" style="overflow: hidden;">
      <div class="cbw-slidedown-search-wrapper cbw-header-search-wrapper cbw-transition transition-height" style="height: 0px;">
        <div class="cbw-slidedown-search-shade cbw-header-search-shade">
          <div class="cbw-slidedown-search-body cbw-mobile-search-body" style="margin: 10px 24px;"></div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
