import Footer from '../packages/footer/src/Components/Footer/Footer';
import Header from '../packages/header/src/Components/Header/Header';
import Slidedown from '../packages/header/src/Components/Slidedown/Slidedown';
import Search from '../packages/header/src/Components/Search/Search';
import Login from '../packages/header/src/Components/Login/Login';
import Notification from '../packages/notification/src/Components/Notification/Notification';
import Notifications from '../packages/notification/src/Components/Notifications/Notifications';

customElements.define('cbw-header', Header);
customElements.define('cbw-slidedown', Slidedown);
customElements.define('cbw-search', Search);
customElements.define('cbw-login', Login);
customElements.define('cbw-footer', Footer);
customElements.define('cbw-notifications', Notifications);
customElements.define('cbw-notification', Notification);
