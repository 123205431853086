import { FooterLink } from './types';

const addQueryParam = (url: string, name: string, value: string) => {
    const pageUrl: URL = new URL(url);
    pageUrl.searchParams.append(name, value);
    return pageUrl.href;
};

const stringToHTML = (div: HTMLElement, str: string) => {
    div.innerHTML = str;

    return div;
};

export const formatLinks = (links: FooterLink[], siteCode: string): FooterLink[] => {
    return links.map(link => {
        link.url = link.url ? addQueryParam(link.url, 'navId', `${siteCode}-${link.linkCode}`) : '';
        return link;
    });
};

// Footer Link Component
const getLink = ({ url, label, name }: FooterLink, color: string) => {
    const linkColorClass = color === 'dark' ? 'cbw-black1' : '';
    return `<li><a href="${url}" 
            class="cbw-footer-link ${linkColorClass}"
            data-cbtrack-linktype="nav" 
            data-cbtrack-label="${label || name}">${label || name}</a></li>`;
};

const getCategory = (links: FooterLink[], heading: string, color: string): HTMLElement => {
    // Wrap category links columns in <ul> and render each Link component within it.
    const categoryLinks = links.map((link: FooterLink) => getLink(link, color));
    const ul = `<ul class="cbw-col-xs-12 cbw-col-sm-12 cbw-col-md-12 cbw-col-lg-12">${categoryLinks.join('')}</ul>`;
    const headingMarkup = heading ? `<h2 class="cbw-footer-title">${heading}</h2>` : '';
    const div: HTMLElement = document.createElement('DIV');
    div.setAttribute('class', 'cbw-footer-category');
    const linkColorClass = color === 'dark' ? 'cbw-black1 cbw-black1-border' : '';
    return stringToHTML(
        div,
        `<div class="cbw-footer-heading ${linkColorClass}">${headingMarkup}</div><div class="cbw-footer-wrapper">${ul}</div>`,
    );
};

export const getLinksMarkup = (links: FooterLink[], color: string): HTMLElement => {
    const linksElement: HTMLElement = document.createElement('DIV');
    ['1', '2', '3'].forEach(column => {
        const div: HTMLElement = document.createElement('DIV');
        div.setAttribute(
            'class',
            `cbw-footer-column cbw-col-xs-12 cbw-col-sm-4 cbw-col-lg-3 ${column === '3' ? 'cbw-offset-lg-3' : ''}`,
        );
        const headers: FooterLink[] = links.filter(
            (link: FooterLink) => link.column === column && link.category === '',
        );
        headers.forEach((header: FooterLink) => {
            const category = getCategory(
                links.filter((link: FooterLink) => link.category === header.name),
                header.label ?? '',
                color,
            );
            div.appendChild(category);
        });
        linksElement.appendChild(div);
    });
    return linksElement;
};
