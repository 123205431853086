import template from './notifications.template';
import fontsStyle from '../../../../core/src/scss/fonts.scss?inline';
import notificationsStyle from './notifications.scss?inline';
import stringReplacer from '../../../../core/src/ts/stringReplacer';
import { addComponentTrackingCode, fireComponentLoaded } from '../../../../core/src/ts/analytics/analytics';
import { apricotVersion } from '../../../../core/src/ts/variables/variables';

class Notifications extends HTMLElement {
    constructor() {
        super();

        // Add a shadow DOM
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const shadowDOM = this.attachShadow({ mode: 'open' });
    }

    connectedCallback() {
        const shadowDOM = this.shadowRoot;
        if (!shadowDOM) {
            return;
        }

        const endpoint = this.getAttribute('endpoint') ?? null;
        const apiFirst = this.hasAttribute('api-notifications-first');

        const replacements: { [key: string]: string } = {
            notificationsStyle: notificationsStyle.toString(),
        };

        shadowDOM.innerHTML = stringReplacer(template, replacements);
        // Add analytics tracking code to the light Dom web component element.
        addComponentTrackingCode('cbw-notifications', 'notification');
        fireComponentLoaded('notification');

        if (endpoint) {
            fetch(endpoint)
                .then(res => {
                    res.json()
                        .then(data => {
                            if (apiFirst) {
                                data = data.reverse();
                            }

                            for (const message of data) {
                                const notification: HTMLElement = document.createElement('cbw-notification');
                                if (message.header) {
                                    notification.setAttribute('header', message.header);
                                }
                                if (message.id) {
                                    notification.setAttribute('nid', message.id);
                                }
                                if (message.type) {
                                    notification.setAttribute('type', message.type);
                                }
                                if (message.message) {
                                    notification.innerHTML = message.message;
                                }
                                if (apiFirst) {
                                    this.prepend(notification);
                                } else {
                                    this.append(notification);
                                }
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
                })
                .catch(error => {
                    console.error(`Notification retrieval error: ${error}`);
                });
        }

        // Use MutationObserver to remove the component when all notifications are removed.
        const observer = new MutationObserver(mutationList => {
            mutationList.forEach(mutation => {
                if (mutation.type === 'childList' && mutation.removedNodes.length > 0) {
                    if (this.children.length - mutation.removedNodes.length < 1) {
                        this.remove();
                        observer.disconnect();
                    }
                }
            });
        });
        observer.observe(this, { childList: true });

        const stylesheet = document.createElement('style');
        stylesheet.textContent = fontsStyle.toString();
        this.appendChild(stylesheet);

        const linkElem = document.createElement('link');
        linkElem.setAttribute('rel', 'stylesheet');
        linkElem.setAttribute('href', `//atlas.collegeboard.org/apricot/prod/${apricotVersion}/widgets.css`);
        shadowDOM.appendChild(linkElem);
    }
}

export default Notifications;
