export default `
<style>{{notificationStyle}}</style>

<div id="notification-{{id}}" class="cbw-notification cbw-widget" part="cbw-widget" role="region" aria-label="Notifications">
  <div class="cbw-notification-wrapper">
    <div class="cbw-notification-icon">
      <i class="cbw-icon cbw-icon-circular {{icon}}" role="img" aria-label="{{type}}"></i>
    </div>
    <div class="cbw-notification-content">
      <h2 class="cbw-notification-title">{{header}}</h2>
      <div class="cbw-notification-description">{{message}}</div>
    </div>
    <div class="cbw-notification-close">
      <button class="cbw-btn" data-cbtrack-linktype="toggle" data-cbtrack-label="Close Notification Icon">
        <i class="cbw-icon cbw-x-mark">
          <span class="sr-only">Close Notification</span>
        </i>
      </button>
    </div>
  </div>
</div>
 `;
